/*###########################################################################
// Modals
//#########################################################################*/

:root {
  --modalOverlayHeight: 100%;
}

.modal {
  position: fixed;
  z-index: 97500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  overflow: auto;
  scroll-behavior: smooth;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--modalOverlayHeight);
    background-color: black;
    opacity: 0.7;
  }

  &.open {
    opacity: 1;
    pointer-events: auto;

    .modal__close {
      opacity: 1;
      pointer-events: auto;
    }

    .modal__content {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

/* Modal box
//------------------*/

.modal__box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 11;

  @include breakpoint(small) {
    padding-top: 80px;
    padding-bottom: 40px;
    padding-left: 16px;
    padding-right: 16px;
  }

  @include breakpoint(medium) {
    padding-top: 100px;
    padding-bottom: 60px;
  }
}

/* Modal content
//------------------*/

.modal__content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

/* Modal content inner
//------------------*/

.modal__content__inner {
  position: relative;
  z-index: 19;
  padding: 15px;
  background-color: #fff;
  border-radius: 6px;
}

/* Modal close
//------------------*/

.modal__close {
  position: sticky;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .modal__close-btn {
    appearance: none;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    opacity: 1;
    transition: all 0.3s;
    width: 44px;
    height: 44px;
    padding: 0;
    cursor: pointer;

    svg {
      width: 44px;
      height: 44px;
    }

    @include breakpoint(medium) {
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

/* Modal overlay
//------------------*/

.modal__overlay {
  width: 100%;
  height: var(--modalOverlayHeight);
  z-index: 10;
}

.sr-only {
	border: 0 none;
	clip: rect(0px, 0px, 0px, 0px);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}