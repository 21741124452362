//###########################################################################
// Information block
//###########################################################################

.information-block {
  padding: 32px 32px 60px 32px;
  background-color: $primary-lighter;
  background-image: url("data:image/svg+xml,%3Csvg width='970' height='154' viewBox='0 0 970 154' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath d='M1440 0v7713H0V0h1440Z'/%3E%3C/clipPath%3E%3CclipPath id='b'%3E%3Cpath d='M970 0v480H0V0h970Z'/%3E%3C/clipPath%3E%3CclipPath id='c'%3E%3Cpath d='M2170 0v898H0V0c0 242.133 485.77 438.42 1085 438.42S2170 242.133 2170 0Z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23a)' transform='translate(-235 -5372)'%3E%3Cg clip-path='url(%23b)' transform='translate(235 5046)'%3E%3Cg clip-path='url(%23c)' transform='translate(-917 31)'%3E%3Cpath fill='%23#{str-replace(quote($primary-main), '#', '')}' d='M917 0h970v449H917V0z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
  border-radius: 8px;

  @include breakpoint(medium) {
    padding: 38px 48px 72px 48px;
  }

  @include breakpoint(large) {
    padding: 60px 60px 100px 60px;
  }

  a {
    @include breakpoint-max(small) {
      word-break: break-word;
    }
  }
}