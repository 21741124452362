//###########################################################################
// Debug variables
//###########################################################################

$debug-breakpoints: (
  "x-small": (
    width: 480px
  ),
  "small": (
    width: 768px
  ),
  "medium": (
    width: 992px
  ),
  "large": (
    width: 1220px
  )
) !global;