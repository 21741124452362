//###########################################################################
// Callout
//###########################################################################

.callout {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: palette(yellow, light);
  border-radius: 8px;
  box-shadow: 0 10px 30px 0 rgba(0,0,0,0.15);
  text-decoration: none;
  font-weight: 500;
  margin-bottom: 40px;
  transition: all 0.2s;

  @include breakpoint(small) {
    justify-content: space-between;
    padding: 20px 40px;
  }

  .callout__icon {
    margin-left: 36px;
  }

  &--asset {
    background: #F1F0F6;

    .callout__file {
      position: relative;
      display: inline-block;
      margin-right: 30px;

      .callout__file__extension {
        position: absolute;
        bottom: 3px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;

        &--small {
          font-size: 0.8rem;
        }
      }
    }
  }

  &--stacked {
    display: block;

    .icon {
      margin-bottom: 20px;
    }
  }

  @include breakpoint(medium) {
    &:hover {
      top: -10px;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
    }
  }
}
