//###########################################################################
// Social callout
//###########################################################################

.social-callout {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-left: 0;

    li {
      @include breakpoint-max(medium) {
        width: 50%;
      }
      @include breakpoint-max(480px) {
        width: 100%;
      }
    }
  }

  &--orange {
    .social-callout__item {
      background: palette(orange);
    }
  }

  &--green {
    .social-callout__item {
      background: palette(green);
    }
  }

  @include breakpoint(480px) {
    margin-left: -25px;
    margin-right: -25px;
  }
}

.social-callout__item {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  background: $primary-main;
  padding: 5px 20px 5px 5px;
  border-radius: 30px;
  text-decoration: none;
  opacity: 1;
  transition: all 0.3s;

  .icon {
    display: inline-block;
    margin-right: 20px;
  }

  p {
    margin-bottom: 0;
  }

  @include breakpoint(medium) {
    &:hover {
      opacity: 0.8;
      top: -4px;
    }
  }

  @include breakpoint(480px) {
    margin-left: 25px;
    margin-right: 25px;
  }
}