//###########################################################################
// Background color utilities
//###########################################################################

.bg--white {
  background-color: palette(white) !important;
}

.bg--black {
  background-color: palette(black) !important;
}

.bg--orange {
  background-color: palette(orange) !important;
}

.bg--orange-light {
  background-color: palette(orange, light) !important;
}

.bg--orange-lighter {
  background-color: palette(orange, lighter) !important;
}

.bg--yellow-lighter {
  background-color: palette(yellow, lighter) !important;
}

.bg--green {
  background-color: palette(green) !important;
}

.bg--green-light {
  background-color: palette(green, light) !important;
}

.bg--green-lighter {
  background-color: palette(green, lighter) !important;
}

.bg--primary-main {
  background-color: $primary-main !important;
}

.bg--primary-secondary {
  background-color: $primary-secondary !important;
}

.bg--primary-soft {
  background-color: $primary-soft !important;
}

.bg--primary-lighter {
  background-color: $primary-lighter !important;
}

// Swoop backgrounds
//------------------

.bg--primary-swoop, .bg--primary-swoop-reverse {
  position: relative;
  background-color: $primary-secondary;
  overflow: hidden;

  .wrapper {
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 280px;
    left: 50%;
    transform: translateX(-50%);
    width: 3200px;
    height: 2200px;
    border-radius: 50%;
    background-color: $primary-main;
  }
}

.bg--primary-swoop-reverse {
  background-color: $primary-main;

  &:after {
    background-color: $primary-secondary;
  }
}

// Duo backgrounds
//------------------

// Primary lighter
//---------

.bg--primary-lighter-xlarge, .bg--primary-lighter-large, .bg--primary-lighter-medium {
  position: relative;
  background-color: palette(white);

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: $primary-lighter;
    top: 0;
    left: 0;
  }

  &.bg--reverse {
    background-color: $primary-lighter;

    &:before {
      background-color: palette(white);
    }
  }
}

.bg--primary-lighter-medium {
  &:before {
    height: 220px;
  }
}

.bg--primary-lighter-large {
  &:before {
    height: 300px;
  }
}

.bg--primary-lighter-xlarge {
  &:before {
    height: 400px;
  }
}

@include breakpoint-max(medium) {
  .bg--primary-lighter-medium--medium-down {
    &:before {
      height: 220px;
    }
  }
}

// Green lighter
//---------

.bg--green-lighter-large, .bg--green-lighter-medium {
  position: relative;
  background-color: palette(white);

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: palette(green, lighter);
    top: 0;
    left: 0;
  }

  &.bg--reverse {
    background-color: palette(green, lighter);

    &:before {
      background-color: palette(white);
    }
  }
}

.bg--green-lighter-medium {
  &:before {
    height: 220px;
  }
}

.bg--green-lighter-large {
  &:before {
    height: 300px;
  }
}

// Orange lighter
//---------

.bg--orange-lighter-large, .bg--orange-lighter-medium {
  position: relative;
  background-color: palette(white);

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    background-color: palette(orange, lighter);
    top: 0;
    left: 0;
  }

  &.bg--reverse {
    background-color: palette(orange, lighter);

    &:before {
      background-color: palette(white);
    }
  }
}

.bg--orange-lighter-medium {
  &:before {
    height: 220px;
  }
}

.bg--orange-lighter-large {
  &:before {
    height: 300px;
  }
}