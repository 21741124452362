//###########################################################################
// Card
//###########################################################################

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: box-shadow .3s;

  .card__media {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    overflow: hidden;

    img {
      display: block;
    }
  }

  .pill {
    position: absolute;
    top: 0;
    right: 20px;
    transform: translateY(-50%);
  }

  .card__bar {
    position: relative;
    width: 42px;
    height: 3px;
    margin-bottom: 20px;
    background-color: $primary-main;

    &--training {
      background-color: palette(yellow);
    }
  }

  .card__title {
    a {
      text-decoration: none;
      color: inherit;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
      }
    }
  }

  .card__content {
    background-color: palette(white);
    padding: 0 20px 35px 20px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    flex: 1 1 auto;
  }

  .card__content__decor {
    display: block;
    position: relative;
    top: 1px;
    width: 100%;
    height: 1px;

    &:before {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 100%;
      height: 44px;
      transform: translateY(-99%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 53.7' preserveAspectRatio='none'%3E%3Cpath fill='%23#{str-replace(quote(palette(white)), '#', '')}' d='M0 .7c84.7 3.8 146.9 9.6 193.5 15.6S300.7 34.9 370 53.6H0V.7z'/%3E%3C/svg%3E");
      background-size: 100% 100%;
    }
  }

  .card__read-more {
    text-decoration: underline;
    font-weight: 600;
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0 20px 30px 0 rgba(34, 51, 67, 0.20);
  }
}


// Card modifiers
//------------------

.card--featured {

  // Medium up modify card, else behave like a standard card
  //
  @include breakpoint(medium) {
    display: flex;
    flex-direction: row;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    .card__media {
      position: relative;
      width: 50%;
      border-radius: 8px;
    }

    .card__content {
      position: unset;
      width: 50%;
      padding: 44px;
      border-bottom-left-radius: 0;
      border-top-right-radius: 8px;

      @include breakpoint(large) {
        padding: 60px;
      }

      .pill {
        right: auto;
        left: 328px;

        @include breakpoint(large) {
          left: 488px;
        }
      }
    }

    .card__content__decor {
      display: none;
    }
  }

  @include breakpoint(small) {
    box-shadow: 0 20px 30px 0 rgba(34, 51, 67, 0.20);
  }
}

// Card cta
//------------------

.card-cta {
  background-color: palette(white);
  padding: 20px;
  box-shadow: 0 10px 20px 0 rgba(34, 51, 67, 0.20);
  border-radius: 6px;

  @include breakpoint(small) {
    padding: 40px;
  }

  @include breakpoint(large) {
    padding: 70px;
  }

  &--dark {
    background-color: $primary-main;
  }

  &--light {
    background-color: $primary-secondary;
  }

  &--adult-services {
    background-color: palette(orange);
  }

  &--adult-services-light {
    background-color: palette(orange, light);
  }

  &--child-services {
    background-color: palette(green);
  }

  &--child-services-light {
    background-color: palette(green, light);
  }

  &--yellow {
    background-color: palette(yellow, light);
  }

  &--yellow-light {
    background-color: palette(yellow, lighter);
  }

  &--sutherland-house-school {
    background-color: palette(sutherland);
  }

  &--sutherland-house-school-light {
    background-color: palette(sutherland, light);
  }

  &--slim {
    @include breakpoint(large) {
      padding: 40px 70px;
    }
  }

  &--height-full {
    height: 100%;
  }
}

// Card cta with image
//------------------

.card-cta-w-image {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: palette(white);
  padding: 20px 20px 0 20px;
  height: 100%;
  box-shadow: 0 10px 20px 0 rgba(34, 51, 67, 0.20);
  border-radius: 6px;

  @include breakpoint(small) {
    padding: 40px 40px 0 40px;
  }

  @include breakpoint(large) {
    padding: 40px 70px 0 70px;
  }

  .card__content {
    margin-bottom: 32px;
  }

  .card__media {
    position: relative;
    display: block;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
    margin-left: -20px;
    margin-right: -20px;

    @include breakpoint(small) {
      margin-left: -40px;
      margin-right: -40px;
    }

    @include breakpoint(large) {
      margin-left: -70px;
      margin-right: -70px;
    }

    img {
      display: block;
    }

    &:after {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 35px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 570 65' preserveAspectRatio='none'%3E%3Cpath fill='%23ffffff' d='M94.15.08C292.96.08 468 25.88 569.99 65V0H0v2.04C27.62.88 55.91.22 84.72.1l9.43-.02Z'/%3E%3C/svg%3E");

      @include breakpoint(small) {
        height: 65px;
      }
    }
  }

  &.card-cta-w-image--justgiving {
    .card__media {
      height: 120px;
      background-color: $primary-secondary;
    }
  }
}

// Card quote
//------------------

.card-quote {
  position: relative;
  padding: 60px 40px;
  margin: 40px 0;
  background-color: $primary-lighter;
  border-left: 7px solid $primary-secondary;

  .quote__text p {
    font-size: 2rem;
  }

  .icon {
    >svg {
      width: 50px;
      height: 50px;
    }
  }

  .icon--top-left {
    position: absolute;
    top: -25px;
    left: 40px;
  }

  .icon--bottom-right {
    position: absolute;
    bottom: -25px;
    right: 40px;
  }

  &--yellow {
    background-color: palette(yellow, light);
    border-left: 7px solid palette(yellow);
  }

  &--orange {
    background-color: palette(orange, lighter);
    border-left: 7px solid palette(orange, light);
  }

  &--green {
    background-color: palette(green, lighter);
    border-left: 7px solid palette(green, light);
  }

  &--pad {
    margin-bottom: 80px;
  }

  @include breakpoint(medium) {
    &--pad {
      margin-left: 100px;
      margin-right: 100px;
    }
  }
}

// Card page
//------------------

.card-page {
  position: relative;
  padding: 28px 26px;
  height: 100%;
  min-height: 230px;
  border-radius: 6px;
  box-shadow: none;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;

  .card__inner {
    position: relative;
    padding-right: 40px;
    z-index: 2;
  }

  .card__link {
    text-decoration: none;
    text-align: right;
    font-weight: bold;
    z-index: 2;

    .icon {

      .inner-circle,
      .inner-arrow {
        transition: fill 0.2s;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: 150px;
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: 1;
    transition: all 0.3s;
    opacity: 0;
  }

  @include breakpoint(medium) {
    &:hover {
      box-shadow: 0px 4px 24px 0px rgba(53, 47, 85, 0.3);

      .card__link {
        .icon {
          .inner-arrow {
            fill: palette(black, font);
          }
        }
      }

      &:after {
        bottom: -1px;
        opacity: 1;
      }
    }
  }

  &--primary {
    background: $primary-secondary;

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23#{str-replace(quote($primary-main), '#', '')}'/%3E%3C/svg%3E");
    }

    @include breakpoint(medium) {
      &:hover {
        .card__link {
          .icon {
            .inner-circle {
              fill: $primary-secondary;
            }
          }
        }
      }
    }

    &.card-page--shs {
      background: $primary-main;

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23#{str-replace(quote($primary-secondary), '#', '')}'/%3E%3C/svg%3E");
      }

      @include breakpoint(medium) {
        &:hover {
          .card__link {
            .icon {
              .inner-circle {
                fill: $primary-main;
              }
            }
          }
        }
      }
    }

    &.card-page--shs-white {
      box-shadow: 0 20px 30px 0 rgba(34, 51, 67, 0.20);
      background: palette(white);

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23#{str-replace(quote($primary-main), '#', '')}'/%3E%3C/svg%3E");
      }

      @include breakpoint(medium) {
        &:hover {
          box-shadow: 0 20px 30px 0 rgba(34, 51, 67, 0.30);
        }
      }
    }
  }

  &--orange {
    background: palette(orange, light);

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23#{str-replace(quote(palette(orange)), '#', '')}'/%3E%3C/svg%3E");
    }

    @include breakpoint(medium) {
      &:hover {
        .card__link {
          .icon {
            .inner-circle {
              fill: palette(orange, light);
            }
          }
        }
      }
    }
  }

  &--green {
    background: palette(green, light);

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23#{str-replace(quote(palette(green)), '#', '')}'/%3E%3C/svg%3E");
    }

    @include breakpoint(medium) {
      &:hover {
        .card__link {
          .icon {
            .inner-circle {
              fill: palette(green, light);
            }
          }
        }
      }
    }
  }

  &--extended {
    .card__inner {
      position: unset;
    }

    .link-arrow {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    @include breakpoint(medium) {
      padding-bottom: 60px;

      &:hover {
        &:after {
          bottom: -5%;
          opacity: 1;
        }
      }
    }
  }
}

// Card popout
//------------------

.card-popout {
  position: relative;
  transform: translateY(0px);
  display: block;
  text-decoration: none;
  transition: all 0.3s;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25));

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 60%;
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: 1;
    transition: all 0.3s;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .card__inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 14px 20px;
    z-index: 2;

    @include breakpoint(medium) {
      padding: 16px 26px;
    }

    @include breakpoint(large) {
      padding: 22px 36px;
    }

    .card__title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-decoration: none;

      h2 {
        margin-right: 32px;
        font-size: 2.2rem;
        margin-bottom: 0;

        @include breakpoint(medium) {
          font-size: 3rem;
        }
      }

      .icon {
        transition: fill 0.3s;

        .inner-arrow {
          fill: palette(black, font);
        }

        .inner-circle {
          transition: fill 0.3s;
        }
      }
    }
  }

  .card__media {
    width: 100%;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;

    img {
      display: block;
    }
  }

  @include breakpoint(medium) {
    &:hover {
      transform: translateY(-10px);
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.35));
    }

    .safari & {
      &:hover {
        transform: translateY(0px);
        filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.25));
      }
    }
  }

  &--school {
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.0));

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 589.5 256' preserveAspectRatio='none'%3E%3Cpath fill='%23#{str-replace(quote(palette(sutherland, light)), '#', '')}' d='M589.5 6.6C542.3 83 388.7 139.1 206.6 139.1c-75.7 0-146.4-9.7-206.6-26.5V256h589.5V6.6z'/%3E%3C/svg%3E") !important;
    }

    .card__media {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 80%;
        background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
      }

      svg {
        height: 31px;
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 2;
      }
    }

    .card__inner {

      @include breakpoint(large) {
        padding: 22px 26px;
      }


      .card__title {
        .icon {
          .inner-circle {
            fill: palette(green, brand) !important;
          }
        }
      }
    }

    .card__inner .card__title {
      h2 {
        font-size: 2.2rem;
        margin-right: 16px;

        @include breakpoint(medium) {
          font-size: 2.2rem;
        }
      }
    }
  }

  &--orange {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 589.5 256' preserveAspectRatio='none'%3E%3Cpath fill='%23#{str-replace(quote(palette(orange)), '#', '')}' d='M589.5 6.6C542.3 83 388.7 139.1 206.6 139.1c-75.7 0-146.4-9.7-206.6-26.5V256h589.5V6.6z'/%3E%3C/svg%3E");
    }

    .card__inner {
      .card__title {
        .icon {
          .inner-circle {
            fill: palette(orange, light);
          }
        }
      }
    }

    @include breakpoint(medium) {
      &:hover {
        .card__title {
          .icon {
            .inner-circle {
              fill: lighten(palette(orange, light), 10%);
            }
          }
        }
      }
    }
  }

  &--green {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 589.5 256' preserveAspectRatio='none'%3E%3Cpath fill='%23#{str-replace(quote(palette(green)), '#', '')}' d='M589.5 6.6C542.3 83 388.7 139.1 206.6 139.1c-75.7 0-146.4-9.7-206.6-26.5V256h589.5V6.6z'/%3E%3C/svg%3E");
    }

    .card__inner {
      .card__title {
        .icon {
          .inner-circle {
            fill: palette(green, light);
          }
        }
      }
    }

    @include breakpoint(medium) {
      &:hover {
        .card__title {
          .icon {
            .inner-circle {
              fill: lighten(palette(green, light), 10%);
            }
          }
        }
      }
    }
  }

  &--primary-main {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 589.5 256' preserveAspectRatio='none'%3E%3Cpath fill='%23#{str-replace(quote($primary-main), '#', '')}' d='M589.5 6.6C542.3 83 388.7 139.1 206.6 139.1c-75.7 0-146.4-9.7-206.6-26.5V256h589.5V6.6z'/%3E%3C/svg%3E");
    }

    .card__inner {
      .card__title {
        .icon {
          .inner-circle {
            fill: $primary-secondary;
          }
        }
      }
    }

    @include breakpoint(medium) {
      &:hover {
        .card__title {
          .icon {
            .inner-circle {
              fill: lighten($primary-secondary, 10%);
            }
          }
        }
      }
    }
  }

  &--primary-secondary {
    &:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 589.5 256' preserveAspectRatio='none'%3E%3Cpath fill='%23#{str-replace(quote($primary-secondary), '#', '')}' d='M589.5 6.6C542.3 83 388.7 139.1 206.6 139.1c-75.7 0-146.4-9.7-206.6-26.5V256h589.5V6.6z'/%3E%3C/svg%3E");
    }

    .card__inner {
      .card__title {
        .icon {
          .inner-circle {
            fill: $primary-main;
          }
        }
      }
    }

    @include breakpoint(medium) {
      &:hover {
        .card__title {
          .icon {
            .inner-circle {
              fill: lighten($primary-main, 10%);
            }
          }
        }
      }
    }
  }
}

// Card tweet
//------------------

.card-social {
  position: relative;
  padding: 20px;
  background: palette(white);
  border-radius: 6px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.04);
  font-size: 1.4rem;

  .card__profile {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding-right: 40px;
    margin-bottom: 25px;

    p {
      margin-bottom: 0;
      line-height: 1.45;
    }

    .card__profile__image {
      margin-right: 15px;
      width: 40px;
      height: 40px;
      flex: 0 0 auto;
      border-radius: 50%;
    }
  }

  .card__link {
    position: absolute;
    top: 20px;
    right: 20px;

    .icon {
      >svg path {
        transition: fill 0.2s;
      }
    }

    &.twitter:hover {
      .icon {
        >svg path {
          fill: #1DA1F2 !important;
        }
      }
    }

    &.fb:hover {
      .icon {
        >svg path {
          fill: #3b5998 !important;
        }
      }
    }
  }
}

// Card team
//------------------

.card-team {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  background-color: #e4c1b8;

  .img__hold {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("/dist/img/bkg/card-team.svg");
    background-size: 104%;
    background-repeat: no-repeat;
    background-position: bottom -2px center;

    @include breakpoint(medium) {
      background-size: 120%;
    }

    @include breakpoint(large) {
      background-size: 104%;
    }
  }

  .text {
    padding: 20px;
    position: relative;
    margin-top: -70px;

    p {
      margin-bottom: 0;
      line-height: 1.4;
    }
  }

  &--school {
    background-color: #A7D5C2;

    .overlay {
      background-image: url("/dist/img/bkg/card-team-school.svg");
    }
  }
}

// Card job
//------------------

.card-job {
  text-decoration: none;
  display: block;
  position: relative;
  background: palette(white);
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform .3s;

  @include breakpoint(small) {
    padding: 30px 240px 30px 30px;

    p {
      margin: 0;
    }

    .btn {
      position: absolute;
      bottom: 30px;
      right: 30px;
    }

    &:hover {
      transform: translateY(-10px);

      .btn {
        background-color: $primary-main-dark;
        color: #fff;
      }
    }
  }

  @include breakpoint(medium) {
    padding: 45px 300px 45px 50px;

    .btn {
      bottom: 50px;
      right: 50px;
    }
  }
}