//###########################################################################
// Footer
//###########################################################################

.footer {
  overflow: hidden;
  position: relative;
  z-index: 1;

  .curve {
    position: relative;
    top: 2px;
    font-size: 0;

    img {
      transform: translateX(-1%);
      width: 102%;
    }
  }

  .logo-contact {
    a {
      @include breakpoint-max(400px) {
        font-size: 1.8rem;
      }
    }
  }

  .logo {
    max-width: 270px;
    width: 100%;
    margin-bottom: 30px;
  }

  .inner {
    background-color: $primary-main;
    padding-top: 30px;
    padding-bottom: 40px;

    p, a {
      color: $primary-font-color;
    }

    a {
      text-decoration: none;
      opacity: 1;
      transition: all 0.2s;

      &:hover {
        opacity: 0.65;
      }
    }
  }

  .ul--row {
    li {
      margin-right: 25px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .copy {
    padding-top: 20px;
    background-color: $primary-secondary;

    p {
      font-size: 1.4rem;
    }
  }

  &--pullup {
    margin-top: -5.25vw;
  }
}

@include breakpoint(small) {
  .footer {
    .logo, .contact p {
      margin-bottom: 0;
    }

    .logo-contact {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}