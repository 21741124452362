//###########################################################################
// Text utilities
//###########################################################################

// Transforms
//------------------

.tt-u {
  text-transform: uppercase !important;
}

// Weights and styles
//------------------

.bold {
  font-weight: 700 !important;
}

.normal, .normal > p {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.light, .light > p  {
  font-weight: 300 !important;
}

em, .italic, i {
  font-style: italic !important;
}

// Decorations
//------------------

.td-n {
  text-decoration: none !important;
}

.td-u {
  text-decoration: underline !important;
}

// Alignment
//------------------

.ta-c {
  text-align: center !important;
}

.ta-l {
  text-align: left !important;
}

.ta-r {
  text-align: right !important;
}

@include breakpoint-max(small) {
  .ta-c--small-down {
    text-align: center !important;
  }
}

@include breakpoint(small) {
  .ta-r--small-up {
    text-align: right !important;
  }
}

@include breakpoint(medium) {
  .ta-r--medium-up {
    text-align: right !important;
  }
}

// Break line
//------------------

.br {
  display: block;
}

// Accessibility
//------------------

.a11y {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}