//###########################################################################
// Iframe utilities
//###########################################################################

.iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }
}

// Aspect ratios
//------------------

.iframe-container--3-2 { padding-bottom: 66.66% !important; }

// Responsive
//

@include breakpoint-max(small) { .iframe-container--3-2-small-down { padding-bottom: 66.66% !important; } }