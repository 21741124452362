//###########################################################################
// Breakpoint mixins
//###########################################################################

@mixin breakpoint($point) {
  @if $point == small {
    @media (min-width: 768px) { @content; }
  } @else if $point == medium {
    @media (min-width: 992px) { @content; }
  } @else if $point == large {
    @media (min-width: 1260px) { @content; }
  } @else if $point == x-large {
    @media (min-width: 1920px) { @content; }
  } @else if $point {
    @media (min-width: $point) { @content; }
  }
}

@mixin breakpoint-max($point) {
  @if $point == small {
    @media (max-width: 767px) { @content; }
  } @else if $point == medium {
    @media (max-width: 991px) { @content; }
  } @else if $point == large {
    @media (max-width: 1259px) { @content; }
  } @else if $point == x-large {
    @media (max-width: 1919px) { @content; }
  } @else if $point {
    @media (max-width: $point) { @content; }
  }
}

@mixin vert_breakpoint($point) {
  @if $point == mini {
    @media (max-height: 840px) {
      @content;
    }
  }
  @if $point == pro {
    @media (max-height: 950px) {
      @content;
    }
  } @else if $point {
    @media (min-height: $point) {
      @content;
    }
  }
}