//###########################################################################
// Forms
//###########################################################################

// Form
//------------------

.form {
  button[type=submit] {
    opacity: 1;
    transition: all 0.3s;
  }

  &.is-hidden {
    display: none;
  }
}

// Input hold
//------------------

.input__hold {
  position: relative;
  margin-bottom: 32px;

  &.has--file {
    input[type=file] {
      color: palette(grey)
    }
  }
}

// Inputs
//------------------

input {
  font-family: $body-font;
  width: 100%;
  border: none;
  border-radius: 0;
  border: 2px solid palette(grey);
  border-radius: 8px;
  background: palette(white);
  font-size: 16px;
  padding: 14px;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  transition: background .3s, border .5s;

  @include breakpoint(small) {
    font-size: 1.6rem;
  }

  &:focus {
    border-color: $primary-main;
    background-color: darken(palette(white), 5%);
  }

  &::placeholder {
    color: rgba(palette(black, font), 0.7);
  }

  &[disabled] {
    background-color: #f7f7f8;
    cursor: not-allowed;
  }
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin: 0;
}

input[type=file] {
  position: relative;
  cursor: pointer;
  padding: 14px;
  color: palette(black, font);
  text-indent: -90px;

  &::placeholder {
    color: palette(black, font);
    opacity: 1;
  }

  &::-webkit-file-upload-button {
    visibility: hidden;
  }

  &:after {
    content: 'Choose file';
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    font-size: 1.6rem;
    background: $primary-soft;
    color: palette(black, font);
    padding: 14px 22px;
    text-indent: initial;
    border-radius: 8px;
  }
}

input[type=radio]:checked, input[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}

input[type=radio]:checked + label, input[type=radio]:not(:checked) + label {
  position: relative;
  padding-right: 35px;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: 1.2;
}

input[type=radio]:checked + label:before, input[type=radio]:not(:checked) + label:before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid palette(grey);
  border-radius: 100%;
  background: transparent;
}

input[type=radio]:checked + label:after, input[type=radio]:not(:checked) + label:after {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 14px;
  height: 14px;
  background: palette(black, font);
  border-radius: 100%;
  transition: all 0.2s ease;
}

input[type=radio]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

input[type=radio]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

/* Radio buttons
//------------------*/

.radio-buttons {
  fieldset > div > div {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 20px 30px;

    .radio {
      grid-column: span 4 / span 4;
    }
  }

  .radio {
    label {
      display: block;
      width: 100%;
      margin-bottom: 0;
      border-radius: 8px;
      padding: 12px 30px;
      text-decoration: none;
      text-align: center;
      line-height: 1.2;
      font-weight: 300 !important;
      color: palette(black, font);
      background: $primary-soft;

      &:after, &:before {
        content: '';
        display: none;
      }

      @include breakpoint(medium) {
        &:hover, &:focus {
          background: $primary-main;
          color: palette(white);
        }
      }
    }
  
    input[type="radio"] {
      appearance: none;
  
      &:checked + label {
        background: $primary-main;
        color: palette(white);
      }
    }
  }
}

// Textarea
//------------------

textarea {
  font-family: $body-font;
  width: 100%;
  border: none;
  border: 2px solid palette(grey);
  border-radius: 8px;
  background: palette(white);
  font-size: 1.6rem;
  line-height: 1.5;
  padding: 14px;
  outline: none;
  transition: background .3s, border .5s;

  &:focus {
    border-color: $primary-main;
    background-color: darken(palette(white), 5%);
  }

  &:not(:empty) {
    background-color: #fbfbfb;
  }
}

// Select
//------------------

select {
  font-family: $body-font;
  color: rgba(palette(black, font), 0.7);
  width: 100%;
  border: solid 2px palette(grey);
  border-radius: 8px;
  line-height: 1.2;
  padding: 14px 34px 14px 14px;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  background-color: palette(white);
  background-image: url('../img/icons/select-arrow-down-black.svg');
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: right 14px top 50%;
  font-size: 1.6rem;
  transition: border-bottom 0.2s;

  &.required {
    border-bottom: solid 2px palette(red, error);
  }
}

// Labels
//------------------

label {
  display: block;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 12px;
  transition: all 0.2s;
}

// Legends
//------------------

legend {
  display: block;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 12px;
  transition: all 0.2s;
}

// Checkboxes
//------------------

.checkbox {
  display: flex;

  label {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    line-height: 1.6;

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 30px;

    &::before, &::after {
      position: absolute;
      content: "";
      /*Needed for the line-height to take effect*/
      display: inline-block;
    }

    &::before {
      height: 16px;
      width: 16px;
      border: solid 2px palette(grey);
      border-radius: 4px;
      left: 1px;
      background-color: transparent;
      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
       *to vertically center it.
       */
      top: 4px;
    }

    &::after {
      color: palette(white);
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 5px;
      top: 9px;
    }
  }

  input[type="checkbox"] {
    display: none;

    + label::after {
      content: none;
    }

    &:checked + label {
      &:before {
        background-color: palette(black);
        border-color: palette(black);
      }

      &::after {
        content: "";
      }
    }

    &:focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
    }

    &:disabled ~ label {
      &:before, &:after { opacity: .5 }
    }
  }

  &.error {
    label::before, label::after {
      animation: shake .5s ease-in-out forwards;
    }
  }
}

.checkbox__group {
  .checkbox {
    display: inline-flex;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      width: 49.5%;
      padding-right: 25px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  input {
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
    position: absolute !important;
    white-space: nowrap;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
}

// Honeypot
//------------------

#website { display: none; } // Honey pot

//###########################################################################
// Form modifiers
//###########################################################################


// Input colours
//------------------

.input--success {
  border-color: palette(green, success);
}

.input--error {
  border-color: palette(red, error);
}

// Form hold
//------------------

.form-container {
  transition: all .3s;
}

.form__success {
  display: none;

  &.is-visible {
    display: block;
  }
}

.form--submitting {
  button[type=submit] {
    animation: submitPulse 2s infinite;
    animation-delay: 0.3s;
    opacity: 0.25;
    pointer-events: none;
  }
}

@keyframes submitPulse {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.25;
  }
}

// Formie overrides
//------------------

.row-mb {
  margin-bottom: 32px !important;
}

.formie-recaptcha-placeholder {
  margin-bottom: 32px;
}

.fui-alert {
  line-height: 1.6 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}