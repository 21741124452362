//###########################################################################
// Icons
//###########################################################################

.icon {
  display: inline-block;
  > svg { display: block; }
  path, polygon { transition: fill .3s; }
}

// Icon colours
//------------------

.icon--white {
  > svg { fill: palette(white) !important; }
}

.icon--black {
  > svg { fill: palette(black) !important; }
}

.icon--primary {
  > svg { fill: $primary-main !important; }
}

.icon--primary-secondary {
  > svg { fill: $primary-secondary !important; }
}

// Colours
//
@each $color, $shades in $palettes {
  $colorName: if($color == grey, '-', '--#{$color}');
  @each $shade, $value in $shades {
    $shadeModifier: if($shade == base, '', '-#{$shade}');

    @if $shade == base {
      $shadeModifier: if($color == grey, '-#{$color}', '');
    } @else {
      $shadeModifier: '-#{$shade}';
    }

    .icon#{$colorName}#{$shadeModifier} {
      > svg {
        fill: palette($color, $shade);
      }
    }
  }
}

// Icon sizes
//------------------

.icon--15 {
  > svg {
    width: 15px;
    height: 15px;
  }
}

.icon--20 {
  > svg {
    width: 20px;
    height: 20px;
  }
}

.icon--25 {
  > svg {
    width: 25px;
    height: 25px;
  }
}

.icon--30 {
  > svg {
    width: 30px;
    height: 30px;
  }
}

.icon--40 {
  > svg {
    width: 40px;
    height: 40px;
  }
}

.icon--60 {
  > svg {
    width: 60px;
    height: 60px;
  }
}

// Responsive
//

@include breakpoint-max(medium) {
  .icon--30-medium-down {
    > svg {
      width: 30px;
      height: 30px;
    }
  }
}