//###########################################################################
// HTML & Body
//###########################################################################

html {
  height: 100%;
  max-width: 100%;
  font-size: 55% !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  min-height: 100% !important;
  background: palette(white);
  scroll-behavior: smooth;

  @include breakpoint(large) {
    font-size: 62.5% !important;
    /* Normalise font size so that 1rem = 10px, 2rem = 20px etc. */
  }
}

body {
  position: relative;
  &.fix { overflow: hidden; }
}

//::-moz-selection {
//  background-color: palette(brand, compliment);
//  color: white;
//}
//
//::selection {
//  background-color: palette(brand, compliment);
//  color: white;
//}

main {
  position: relative;
  z-index: 1;
  @extend %clearfix;
  overflow: hidden;
  padding-top: 61px;

  @include breakpoint(medium) {
    padding-top: 157px;
  }

  @include breakpoint(large) {
    padding-top: 157px;
  }

  &.main--home {
    padding-top: 61px;
  }
}