//###########################################################################
// Filters
//###########################################################################

// Filter bar
//------------------

.filter-bar {
  margin-top: 15px;
  background-color: $primary-soft;
  border-radius: 6px;
  padding: 20px;

  @include breakpoint(small) {

    padding: 20px 60px;

    p {
      margin-bottom: 0;
    }
  }
}
