//###########################################################################
// Hero
//###########################################################################

.hero {
  position: relative;
  padding-top: 35px;
  background-color: $primary-main;

  .breadcrumb {
    //margin-bottom: 15px;
    margin-bottom: 3.5vh;
  }

  .curve {
    position: relative;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);
    width: 101%;
    padding-top: 20px;
    font-size: 0;
    line-height: 0;
  }

  .inner {
    .gs__1-2:first-child {
      @include breakpoint(large) {
        padding-right: 40px;
      }
    }
  }
}

// Hero intro image
//------------------

.hero__intro-image {
  border-radius: 8px;
  overflow: hidden;
  margin-top: -200px;

  img {
    display: block;
  }

  @include breakpoint(large) {
    margin-top: -300px;
  }
}

// Hero modifiers
//------------------

.hero--light {
  background-color: $primary-lighter;
}

// Hero curve absolute
//

.hero--curve-absolute {
  .curve {
    position: absolute;
    top: auto;
    bottom: -2px;
    left: 50%;
    width: 101%;
    transform: translateX(-50%);
  }
}

// Hero home
//

.hero--home {
  padding-top: 0;
  background-color: transparent;

  .inner {
    padding: 50px 0 115vw 0;

    @include breakpoint(576px) {
      padding: 50px 0 100px 0;
    }

    @include breakpoint(small) {
      padding: 250px 0 160px 0;
    }
  }

  .hero__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translateX(-50%) translateY(-50%);
      object-fit: cover;
    }

    &--desktop {
      display: none;

      img {
        filter: brightness(50%) contrast(110%);
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(172, 172, 172, 0.1) 25%);
      }

      @include breakpoint(576px) {
        display: block;
      }
    }

    &--mobile {
      img {
        top: auto;
        bottom: 0;
        object-position: bottom;
        transform: translateX(-50%);
        filter: brightness(50%) contrast(110%);
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(172, 172, 172, 0.1) 35%);
      }

      @include breakpoint(576px) {
        display: none;
      }
    }
  }

  &.hero--home-shs {
    .hero__image {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(#FFFFFF, 0.1) 25%);
      }

      img {
        filter: brightness(115%);
      }
    }
  }
}


// Hero training
//

.hero--training {
  background-color: palette(yellow);

  .inner {
    margin-bottom: 40px;
  }
}

.hero--training-light {
  background-color: palette(yellow, lighter);
}

.hero--training, .hero--training-light {
  .curve {
    margin-top: -40px;
  }
}

// Hero adult services
//

.hero--as {
  background-color: palette(orange);

  .inner {
    margin-bottom: 40px;
  }
}

.hero--as-light {
  background-color: palette(orange, lighter);
}

.hero--as, .hero--as-light {
  .curve {
    margin-top: -40px;
  }
}

// Hero child services
//

.hero--cs {
  background-color: palette(green);

  .inner {
    margin-bottom: 40px;
  }
}

.hero--cs-light {
  background-color: palette(green, lighter);
}

.hero--cs, .hero--cs-light {
  .curve {
    margin-top: -40px;
  }
}

// Hero shs
//

.hero--shs {
  background-color: $primary-secondary;

  .inner {
    margin-bottom: 40px;
  }
}

// Hero intro image
//

.hero--intro-image {
  .curve {
    padding-top: 200px;
  }

  @include breakpoint(large) {
    .curve {
      padding-top: 250px;
    }
  }
}