//###########################################################################
// Breadcrumb
//###########################################################################

.breadcrumb {
  display: none;

  a {
    text-decoration: none;
    color: $primary-font-color;
  }

  li {
    color: $primary-font-color;
    margin-right: 8px;
    font-size: 1.4rem;
    font-weight: 300;

    &:first-of-type {
      margin-left: 0;
    }

    a {
      font-size: 1.4rem;
      font-weight: 300;
      opacity: 1;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  @include breakpoint(small) {
    display: block;
  }

  &--dark {
    a {
      color: palette(black, font);
    }

    li {
      color: palette(black, font);
    }
  }
}