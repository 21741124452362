//###########################################################################
// Notice
//###########################################################################

.notice {
  position: relative;
  background: $primary-soft;
  border-left: 7px solid $primary-main;
  padding: 48px 30px;
  font-size: 2.6rem;
  margin-top: 40px;

  p:last-child {
    margin-bottom: 0;
  }

  &:after {
    content: '?';
    position: absolute;
    top: 0;
    left: 30px;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 2.6rem;
    line-height: 1;
    color: palette(white);
    background: $primary-main;
    border-radius: 50%;
    padding: 10px 16px;
  }
}
