//###########################################################################
// Article
//###########################################################################

// Article information
//------------------

.article__information {
  margin-top: 65px;
  margin-bottom: 30px;

  @include breakpoint(420px) {
    margin-top: 25px;
  }

  li {
    padding-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }
}

.article__footer {
  padding: 32px 0;
  margin: 32px 0;
  border-top: 1px solid palette(grey);
  border-bottom: 1px solid palette(grey);
}