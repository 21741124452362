//###########################################################################
// Colour utilities
//###########################################################################

.color--white {
  color: palette(white) !important;
}

.color--black {
  color: palette(black) !important;
}

.color--orange {
  color: palette(orange) !important;
}

.color--orange-light {
  color: palette(orange, light) !important;
}

.color--green {
  color: palette(green) !important;
}

.color--green-light {
  color: palette(green, light) !important;
}

.color--primary {
  color: $primary-main !important;
}

.color--primary-dark {
  color: $primary-main-dark !important;
}