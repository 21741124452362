//###########################################################################
// Cookies
//###########################################################################

#cookie-message {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  padding: 25px;

  .inner {
    background-color: $primary-main;
    padding: 25px;
    box-shadow: 0 10px 20px 0 rgba(31, 30, 65, 0.24);
    border-radius: 10px;
  }

  .wrapper {
    padding: gutter(2) gutter(1);
  }

  .btn {
    margin-bottom: 15px;
  }

  .btn:first-of-type {
    margin-right: 15px;
  }

  .g {
    .h3 {
      margin-bottom: 0;
    }
  }
}

@include breakpoint(small) {
  #cookie-message {
    .inner {
      padding: 45px 45px 25px;
    }

    .g {
      margin-bottom: 25px;
      p {
        margin-bottom: 0;
      }
    }

    .button__hold {
      margin-top: 30px;
    }
  }
}

@include breakpoint(medium) {

  #cookie-message {
    width: 590px;
  }
}

@include breakpoint(large) {

  #cookie-message {
    width: 630px;
  }
}