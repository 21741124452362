//###########################################################################
// Services block
//###########################################################################

.services-block {
  position: relative;
  height: 100%;
  padding-bottom: 35px;

  .link-arrow {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

