//###########################################################################
// Animation utilities
//###########################################################################

.is-loading {
  animation: isLoading 1.5s infinite ease-in-out;
}

@keyframes isLoading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}