//###########################################################################
// Typography variables
//###########################################################################

// Fonts
//------------------

$body-font: canada-type-gibson;
$heading-font: questa-sans, sans-serif;

// Base
//------------------
$base-font-size: 1.8rem;
$base-line-height: 28;
$min-font: 17px;
$max-font: 21px;

// Headings
//------------------

$h1-size: 4.5rem;
$h2-size: 4rem;
$h3-size: 3rem;
$h4-size: 2.4rem;

.questa-sans {
  font-family: questa-sans, sans-serif;
  font-weight: 900;
  font-style: normal;
}

// Paragraphs
//------------------

$p-size: 1.8rem;
$p-size--small: 1.4rem;
$p-size--big: 2.2rem;
$small-font-size: 1.6rem;
$lead-font-size: 2.2rem;

.gibson-book {
  font-family: canada-type-gibson, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.gibson-medium {
  font-family: canada-type-gibson, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.gibson-semi {
  font-family: canada-type-gibson, sans-serif;
  font-weight: 600;
  font-style: normal;
}