//###########################################################################
// Data tooltip
//###########################################################################

[data-tooltip] {
  position: relative;
  cursor: pointer;
}

button[data-tooltip] {
  padding: 0;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
  z-index: 1;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-top: 8px;
  margin-left: -45px;
  padding: 9px 9px;
  width: 90px;
  border-radius: 10px;
  background-color: $primary-main-dark;
  color: palette(white);
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: $heading-font;
}

.tooltip--big {
  display: block;
}

.tooltip--big[data-tooltip]:before {
  width: 130px;
  margin-left: -65px;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  top: 100%;
  left: 52%;
  margin-top: 3px;
  width: 0;
  border-bottom: 5px solid $primary-main-dark;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: ' ';
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.copy-clipboard {
  display: inline-flex;
  align-items: center;
  background-color: $primary-soft;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  padding: 8px 18px !important;

  .icon {
    margin-left: 14px;
  }
}