// ==========================================================================
// HIDDEN-VISIBLE
// ==========================================================================
// HIDDEN

.is-hidden {
    display: none;
}

.is-hidden--sm {
    @include breakpoint(small) {
        display: none !important;
    }
}

.is-hidden--md {
    @include breakpoint(medium) {
        display: none !important;
    }
}

.hidden {
    display: none !important;
}

// VISIBLE

.is-visible {
    display: block;
}

.is-visible--md {
    display: none;

    @include breakpoint(medium) {
        display: block !important;
    }
}
