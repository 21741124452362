//###########################################################################
// Hamburger
//###########################################################################

.navigation-burger {
  position: relative;
  z-index: 10;
  display: inline-block;
  background-color: white;
  border-radius: 50%;
  padding: 9px 9px;
  width: 40px;
  height: 40px;
}

.hamburger {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  height: 15px;
  margin: 0;
  position: relative;
  width: 22px;
  outline: none;

  span {
    height: 0;
    border-bottom: 2px solid $primary-main;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    transition: all 200ms ease-out;
    backface-visibility: hidden;
    border-radius: 2px;
  }

  &:hover {
    span {width: 100%;}
  }

  &:after, &:before {
    transform: translateY(-50%);
    position: absolute;
    border-bottom: 2px solid $primary-main;
    border-radius: 2px;
    content: '';
    height: 0;
    width: 100%;
    right: 0;
    transition: all .3s ease-out;
  }

  &:before {top: 0;}
  &:after {top: 100%;}

  &.open {
    &:before {
      top: 50%;
    }
    &:after {
      top: 50%;
    }
  }
}

@include breakpoint(medium) {
  .navigation-burger { display: none; }
}

