//###########################################################################
// Timeline
//###########################################################################

.timeline {
  position: relative;
  padding-bottom: 30px;

  &::before {
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: $primary-main;
    border-radius: 3px;
  }

  &::after {
    content: "";
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    height: 45px;
    width: 3px;
    position: absolute;
    bottom: 0;
  }

  @include breakpoint(small) {
    &::before {
      left: 50%;
      transform: translateX(-50%);
      right: initial;
    }

    &::after {
      left: 50%;
      transform: translateX(-50%);
    }

    .timeline__row {
      display: flex;
      align-items: center;
      position: relative;



      > * {
        flex: 1;
      }

      &:first-of-type {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 50%;
          width: 10px;
          background: white;
        }
      }
    }


    .invis {
      padding: 20px;
      margin-bottom: 30px;
      margin-left: 40px;
    }
  }

  @include breakpoint(medium) {
    .invis {
      padding: 35px;
    }

    .timeline__row {
      margin-bottom: 40px;
    }
  }

  @include breakpoint(large) {
    .invis {
      padding: 35px;
      margin-left: 70px;
    }
  }
}

.timeline__block {
  background-color: $primary-lighter;
  padding: 20px;
  margin-bottom: 30px;
  margin-left: 40px;
  position: relative;
  border-radius: 6px;

  &::before {
    content: "";
    position: absolute;
    left: -68px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("/dist/img/icons/timeline-dot.svg");
    background-position: center;
    background-size: 58px 58px;
    height: 58px;
    width: 58px;
  }

  &::after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15.5px 20px 15.5px 0;
    border-color: transparent $primary-lighter transparent transparent;
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @include breakpoint(small) {

    &--even {
      &::before {
        content: none;
      }
    }

    &--odd {
      margin-left: 0;
      margin-right: 40px;

      &::before {
        right: -68px;
        left: initial;
      }


      &::after {
        border-width: 15.5px 0 15.5px 20px;
        border-color: transparent transparent transparent $primary-lighter;
        left: 100%;
        right: initial;
      }
    }

  }

  @include breakpoint(medium) {
    padding: 35px;

    &--even {
      margin-left: 70px;
    }

    &--odd {
      margin-left: 0;
      margin-right: 70px;

      &::before {
        right: -98px;
        left: initial;
      }
    }
  }
}

