//###########################################################################
// Buttons
//###########################################################################

// Button unstyled
//------------------

@mixin button-unstyled {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;

  &:focus, &:active {
    outline: none;
  }
}
