//###########################################################################
// Calendar
//###########################################################################

.calendar {

}

// Calendar list
//------------------

.calendar__list {
  background: palette(white);
  box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding-right: 4px;
  padding-bottom: 12px;

  table {
    width: 100%;

    thead {
      @include breakpoint-max(small) {
        display: none;
      }

      tr {
        width: calc(100% - 0.4rem);

        @include breakpoint(large) {
          width: calc(100% - 0.5rem);
        }

        th {
          padding: 16px 20px;
          font-size: 2.2rem;
          font-weight: bold;
          text-align: left;
        }
      }
    }

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;

      td {
        padding: 16px 20px;
        font-size: 1.8rem;
        text-align: left;

        @include breakpoint-max(small) {
          display: block;
        }

        @include breakpoint(small) {
          &:not(:last-child) {
            border-right: 2px solid palette(green);
          }
        }
      }

      &.highlight {
        animation: highlightRow 0.75s;
        animation-delay: 200ms;
        animation-timing-function: cubic-bezier(0,0,.58,1);
      }

      @keyframes highlightRow {
        0% {
          background: palette(white);
        }
        50% {
          background: palette(green, light);
        }
        100% {
          background: palette(white);
        }
      }

      @keyframes highlightRowShs {
        0% {
          background: palette(white);
        }
        50% {
          background: $primary-soft;
        }
        100% {
          background: palette(white);
        }
      }
    }

    tbody {
      display: block;
      width: 100%;
      max-height: 524px;
      overflow: auto;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #e6e6e6;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: palette(green);
        border-radius: 10px;
        border: 3px solid palette(green);
      }
    }
  }
}

// Calendar event
//------------------

.calendar__event {
  @include breakpoint-max(small) {
    .calendar__event__date, .calendar__event__time, .calendar__event__title {
      &:before {
        display: inline;
        font-weight: bold;
      }
    }

    .calendar__event__date {
      &:before {
        content: 'Date: ';
      }
    }

    .calendar__event__time {
      &:before {
        content: 'Time: ';
      }
    }

    .calendar__event__title {
      &:before {
        content: 'Event: ';
      }
    }

    &:not(:last-child) {
      border-bottom: 2px solid palette(green, light);
    }
  }

  &.hidden {
    display: none;
  }
}

// Calendar aside
//------------------

.calendar__aside {
  width: 100%;
  height: 100%;
  background: palette(white);
  box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

// Calendar datepicker
//------------------

.calendar__datepicker {
  position: relative;

  .qs-datepicker-container {
    top: 0 !important;
    left: 0 !important;
  }
}

// Calendar filter
//------------------

.calendar__filter {
  padding: 35px 12px;

  fieldset {
    legend {
      font-family: $heading-font !important;
      font-size: 2.2rem;
      margin-bottom: 16px;
    }

    .calendar__filter__radio {
      margin-bottom: 6px;

      label {
        width: 100%;
      }
    }
  }
}

// Calendar modifiers
//------------------

.calendar--shs {
  .calendar__event {
    @include breakpoint-max(small) {
      &:not(:last-child) {
        border-bottom: 2px solid $primary-main;
      }
    }
  }

  .calendar__list {
    table {
      tr {
        td {
          @include breakpoint(small) {
            &:not(:last-child) {
              border-right: 2px solid $primary-main;
            }
          }
        }

        &.highlight {
          animation: highlightRowShs 0.75s;
          animation-delay: 200ms;
          animation-timing-function: cubic-bezier(0,0,.58,1);
        }
      }

      tbody {
        &::-webkit-scrollbar-thumb {
          background-color: $primary-main;
          border-radius: 10px;
          border: 3px solid $primary-main;
        }
      }
    }
  }

  .qs-datepicker-container {
    .qs-controls {
      .qs-arrow {
        &.qs-left {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 23 23'%3E%3Cpath fill='%23#{str-replace(quote($primary-main), '#', '')}' d='M11.5 0C5.1 0 0 5.1 0 11.5S5.1 23 11.5 23 23 17.9 23 11.5 17.9 0 11.5 0zm2.3 17.8l-6.3-6.3.1-.1 1.5-1.5 4.5-4.5L15.3 7l-4.5 4.5 4.7 4.7-1.7 1.6z'%3E%3C/path%3E%3C/svg%3E");
        }

        &.qs-right {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 23 23'%3E%3Cpath fill='%23#{str-replace(quote($primary-main), '#', '')}' d='M11.5 23C17.9 23 23 17.9 23 11.5S17.9 0 11.5 0 0 5.1 0 11.5 5.1 23 11.5 23zM9.2 5.2l6.3 6.3-.1.1-1.5 1.5-4.5 4.5L7.7 16l4.5-4.5-4.7-4.7 1.7-1.6z'%3E%3C/path%3E%3C/svg%3E");
        }
      }
    }

    .qs-event {
      &:after {
        border: 2px solid $primary-main;
      }
    }

    .qs-active {
      background: transparent !important;

      &.qs-event {
        &:after {
          border: 2px solid $primary-main;
          background: $primary-main !important;
        }
      }
    }
  }
}

// Datepicker overrides
//
.datepicker {
  display: none;
}

.qs-datepicker-container {
  position: unset !important;
  font-family: $body-font !important;
  font-size: 1.6rem !important;
  width: 100% !important;
  color: inherit !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;

  .qs-controls {
    background: transparent !important;
    padding: 15px 8px;

    .qs-arrow {
      height: 20px;
      width: 20px;

      &:hover {
        background-color: transparent !important;
      }

      &.qs-left {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 23 23'%3E%3Cpath fill='%23#{str-replace(quote(palette(green)), '#', '')}' d='M11.5 0C5.1 0 0 5.1 0 11.5S5.1 23 11.5 23 23 17.9 23 11.5 17.9 0 11.5 0zm2.3 17.8l-6.3-6.3.1-.1 1.5-1.5 4.5-4.5L15.3 7l-4.5 4.5 4.7 4.7-1.7 1.6z'%3E%3C/path%3E%3C/svg%3E");

        &:after {
          content: unset !important;
        }
      }

      &.qs-right {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 23 23'%3E%3Cpath fill='%23#{str-replace(quote(palette(green)), '#', '')}' d='M11.5 23C17.9 23 23 17.9 23 11.5S17.9 0 11.5 0 0 5.1 0 11.5 5.1 23 11.5 23zM9.2 5.2l6.3 6.3-.1.1-1.5 1.5-4.5 4.5L7.7 16l4.5-4.5-4.7-4.7 1.7-1.6z'%3E%3C/path%3E%3C/svg%3E");

        &:after {
          content: unset !important;
        }
      }
    }
  }

  .qs-month-year {
    font-size: 2rem !important;
    font-family: $heading-font !important;
    pointer-events: none;

    @include breakpoint(large) {
      font-size: 2.2rem !important;
    }
  }

  .qs-square {
    position: relative;
    height: 39px !important;
    border-radius: 50% !important;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      background: palette(grey, lighter);
      border: 2px solid palette(grey, lighter);
      border-radius: 50%;
      width: 24px !important;
      height: 24px !important;
      z-index: -1;
      opacity: 0;
      transition: opacity 0.2s;

      @include breakpoint(large) {
        width: 32px !important;
        height: 32px !important;
      }
    }

    &:hover {
      background: transparent !important;

      &:after {
        opacity: 1;
      }
    }
  }

  .qs-event {
    &:after {
      right: auto !important;
      left: 50% !important;
      top: 50% !important;
      bottom: auto !important;
      transform: translateX(-50%) translateY(-50%) !important;
      background: none !important;
      border: 2px solid palette(green, light);
      width: 24px !important;
      height: 24px !important;
      opacity: 1;

      @include breakpoint(large) {
        width: 32px !important;
        height: 32px !important;
      }
    }

    &:hover {
      &:after {
        background: palette(grey, lighter) !important;
      }
    }
  }

  .qs-active {
    background: transparent !important;

    &.qs-event {
      &:after {
        right: auto !important;
        left: 50% !important;
        top: 50% !important;
        bottom: auto !important;
        transform: translateX(-50%) translateY(-50%) !important;
        background: palette(green, light) !important;
        border: 2px solid palette(green, light);
        width: 24px !important;
        height: 24px !important;
        z-index: -1;
        opacity: 1;

        @include breakpoint(large) {
          width: 32px !important;
          height: 32px !important;
        }
      }
    }
  }
}