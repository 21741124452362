//###########################################################################
// Spacing utilities
//###########################################################################

// Bumps
//------------------

.bump {
  margin-bottom: 20px !important;
}

.bump--sm {
  margin-bottom: 40px !important;
}

.bump--md {
  margin-bottom: 60px !important;
}

.bump--none {
  margin-bottom: 0 !important;
}

.bump--hero {
  margin-bottom: 400px !important;
}

// Responsive
//

@include breakpoint-max(small) {
  .bump--small-down {
    margin-bottom: 20px !important;
  }

  .bump--sm-small-down {
    margin-bottom: 40px !important;
  }
}

@include breakpoint-max(medium) {
  .bump--medium-down {
    margin-bottom: 20px !important;
  }

  .bump--sm-medium-down {
    margin-bottom: 40px !important;
  }
}

// Nudges
//------------------

.nudge {
  margin-right: 15px !important;
}

// Nudges using padding
//------------------

@include breakpoint(large) {
  .p-nudge-lg-large-up {
    padding-right: 45px !important;
  }
}

// Pullups
//------------------

.pullup--intro {
  margin-top: -5vw;
}

.pullup {
  margin-top: -250px;
}

.pullup--lg {
  margin-top: -435px;
}

// Spacers
//------------------

.spacer {
  margin-bottom: 8vh;

  &--large {
    margin-bottom: 8vh;

    @include breakpoint(small) {
      margin-bottom: 15vh;
    }
  }

  &-top {
    margin-top: 8vh;

    @include vert_breakpoint(1500px) {
      margin-top: 4vh;

      &-top {
        margin-top: 4vh;
      }
    }

    &--large {
      margin-top: 8vh;

      @include breakpoint(small) {
        margin-top: 15vh;
      }
    }
  }

  @include vert_breakpoint(1500px) {
    margin-bottom: 4vh;

    &-top {
      margin-top: 4vh;
    }
  }
}

// Spacers using padding
//------------------

.p-spacer {
  padding-bottom: 8vh;

  &--large {
    padding-bottom: 8vh;

    @include breakpoint(small) {
      padding-bottom: 15vh;
    }
  }

  &-top {
    padding-top: 8vh;

    &--large {
      padding-top: 8vh;
      @include breakpoint(small) {
        padding-top: 15vh;
      }
    }
  }

  @include vert_breakpoint(1500px) {
    padding-bottom: 4vh;

    &-top {
      padding-top: 4vh;
    }
  }
}

// Spacer for about services section
//------------------

.p-spacer-about-services {
  padding-bottom: 380px;
}