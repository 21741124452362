//###########################################################################
// Button
//###########################################################################

.btn {
  text-decoration: none;
  text-align: center;
  font-size: 2rem;
  line-height: 1.2;
  font-family: $heading-font;
  font-weight: 500;
  outline: none;
  padding: 12px 30px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
  user-select: none;
  border: none;
  border-radius: 30px;
  background-color: $primary-main;
  color: palette(white);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: $primary-main-dark;
      color: #fff;
    }
  }
}


//###########################################################################
// Button modifiers
//###########################################################################

// Theme buttons
//------------------

.btn--primary-secondary {
  background-color: $primary-secondary;
  color: palette(black, font);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: lighten($primary-secondary, 10%);
      color: palette(black, font);
    }
  }
}

.btn--primary-soft {
  background-color: $primary-soft;
  color: palette(black, font);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: lighten($primary-soft, 10%);
      color: palette(black, font);
    }
  }
}

.btn--primary-dark {
  background-color: $primary-main-dark;
  color: palette(white);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: lighten($primary-main-dark, 10%);
      color: palette(white);
    }
  }
}

// Colours
//------------------

.btn--white {
  background-color: palette(white);
  color: palette(black, font);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: darken(palette(white), 10%);
      color: palette(black, font);
    }
  }
}

.btn--white-outline {
  background-color: transparent;
  border: 2px solid white;

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: darken(palette(white), 10%);
      color: palette(black, font);
    }
  }
}

.btn--black {
  background-color: palette(black, font);
  color: palette(white);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: lighten(palette(black, font), 10%);
      color: palette(white);
    }
  }
}

.btn--orange {
  background-color: palette(orange);
  color: palette(black, font);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: palette(orange, light);
      color: palette(black, font);
    }
  }
}

.btn--orange-light {
  background-color: palette(orange, light);
  color: palette(black, font);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: lighten(palette(orange), 5%);
      color: palette(black, font);
    }
  }
}

.btn--yellow {
  background-color: palette(yellow);
  color: palette(black, font);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: palette(yellow, light);
      color: palette(black, font);
    }
  }
}

.btn--yellow-light {
  background-color: palette(yellow, light);
  color: palette(black, font);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: lighten(palette(yellow), 5%);
      color: palette(black, font);
    }
  }
}

.btn--green {
  background-color: palette(green);
  color: palette(white);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: darken(palette(green), 7.5%);
      color: palette(white);
    }
  }
}

.btn--green-light {
  background-color: palette(green, light);
  color: palette(black, font);

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: lighten(palette(green), 5%);
      color: palette(black, font);
    }
  }
}

// Sizes
//------------------

.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px;
}

.btn--large {
  font-size: 2.8rem;
  line-height: 3.3rem;
  padding: 14px 40px;
}

// Other
//------------------

.btn--justgiving {
  align-items: center;
  background-color: #7A04DD;
  color: palette(white);
  border-radius: 4px;

  .icon {
    vertical-align: middle;
    margin-left: 6px;

    > svg {
      width: 100px;
      height: auto;
    }
  }

  @include breakpoint(medium) {
    &:hover, &:focus {
      background-color: lighten(#7A04DD, 5%);
      color: palette(white);
    }
  }
}