//###########################################################################
// Links
//###########################################################################

.link, .link--fake {
  cursor: pointer;
  text-decoration: none;
  color: palette(black, font);
  font-weight: 400;
  transition: color .3s;
}

// Link with icon
//------------------

.link--icon {
  span {
    margin-left: 10px;
  }
}

// Link with arrow
//------------------

.link-arrow {
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  text-decoration: none;
  color: palette(black, font);
  padding-right: 38px;
  background-position: right 1px center;
  background-size: 22px;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(0 .255)' fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23#{str-replace(quote($primary-main), '#', '')}' cx='11' cy='11' r='11'/%3E%3Cpath stroke='%23FFF' stroke-width='2' stroke-linecap='square' d='m9.125 7.125 3.75 3.75M13 11l-4 4'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  line-height: 1.5;
  opacity: 1;
  transition: all 0.3s;

  @include breakpoint(medium) {
    &:hover {
      opacity: 0.6;
      padding-right: 42px;
    }
  }
}

.link-arrow--primary-secondary {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(0 .255)' fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23#{str-replace(quote($primary-secondary), '#', '')}' cx='11' cy='11' r='11'/%3E%3Cpath stroke='%23FFF' stroke-width='2' stroke-linecap='square' d='m9.125 7.125 3.75 3.75M13 11l-4 4'/%3E%3C/g%3E%3C/svg%3E");
}

.link-arrow--orange {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(0 .255)' fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23#{str-replace(quote(palette(orange)), '#', '')}' cx='11' cy='11' r='11'/%3E%3Cpath stroke='%23FFF' stroke-width='2' stroke-linecap='square' d='m9.125 7.125 3.75 3.75M13 11l-4 4'/%3E%3C/g%3E%3C/svg%3E");
}

.link-arrow--green {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='23' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(0 .255)' fill='none' fill-rule='evenodd'%3E%3Ccircle fill='%23#{str-replace(quote(palette(green)), '#', '')}' cx='11' cy='11' r='11'/%3E%3Cpath stroke='%23FFF' stroke-width='2' stroke-linecap='square' d='m9.125 7.125 3.75 3.75M13 11l-4 4'/%3E%3C/g%3E%3C/svg%3E");
}