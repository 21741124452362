//###########################################################################
// Colours variables
//###########################################################################

// Palettes
//------------------

$palettes: (
        white: (
                base: #ffffff
        ),
        black: (
                base: #333333,
                font: #223343
        ),
        grey: (
                lighter: #F3F3F3,
                light: #eaeaea,
                base: #D1DDE6,
                dark: #3a3a3a
        ),
        orange: (
                lighter: #FFEEE2,
                light: #EEAA79,
                base: #EA8A5D
        ),
        yellow: (
                lighter: #FFF6E5,
                light: #F5DBA8,
                base: #E9B753
        ),
        green: (
                lighter: #F8FFED,
                light: #CEDBAD,
                base: #8A9366,
                success: #32b292,
                brand: #74A68E
        ),
        sutherland: (
                light: #A7D5C2,
                base: #74A68E
        ),
        red: (
                error: #EE3D52
        )
) !global;