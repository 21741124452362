//###########################################################################
// Typography
//###########################################################################

// Headings
//------------------

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  font-family: $heading-font;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 32px;
}

h1, .h1 {
  font-size: $h1-size;
}

h2, .h2 {
  font-size: $h2-size;
}

h3, .h3 {
  font-size: $h3-size;
}

h4, .h4 {
  font-size: $h4-size;
}

// Base
//------------------

body {
  font-family: $body-font;
  font-style: normal;
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 1.7;
  color: palette(black, font);
}

// Text
//------------------

p, .p {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  line-height: inherit;
  margin-bottom: 20px;
}

.small > p,
.small > .p,
.small {
  font-size: $p-size--small !important;
}

.big > p,
.big > .p,
.big {
  font-size: $p-size--big !important;
}

strong { font-weight: bold; }
em { font-style: italic; }

// Lists
//------------------

ul, ol {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px;

  &.ul--unstyled, &.ul--row {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0 0 15px;
    }
  }

  &.ul--row {
    li {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li {
  font-size: inherit;
  line-height: 1.5;
  font-weight: 300;
  color: inherit;
  padding-bottom: 15px;
}