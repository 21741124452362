//###########################################################################
// Pagination
//###########################################################################

.pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 40px;

  .pagination__number, .pagination__current {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    transition: all 0.2s;
    text-decoration: none;
  }

  .pagination__number {
    &:hover {
      font-weight: bold;
    }
  }

  .pagination__current {
    position: relative;
    font-weight: bold;
    color: $primary-main;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 4px;
      background: $primary-main;
    }
  }

  .pagination__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    transition: all 0.2s;
    opacity: 1;

    &:hover {
      opacity: 0.5;
    }

    svg {
      fill: palette(black);
      width: 22px;
      height: auto;
    }
  }

  .pagination__dots {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 20px;
  }
}
