//###########################################################################
// Image utilities
//###########################################################################

.img--responsive {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.img--background {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @include cover();
    font-family: 'object-fit: cover;'; // Polyfill for object-fit
}

.img--rounded {
    border-radius: 6px;
}

.img--shadow {
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
}