//###########################################################################
// Top posts
//###########################################################################

.top-posts {
  height: 100%;
  background-color: $primary-lighter;
  padding: 20px;
  border-radius: 6px;

  ol {
    list-style: none;
    margin-left: 0;
    counter-reset: item;
  }

  li {
    counter-increment: item;
    margin-bottom: 5px;
    position: relative;
    padding-left: 30px;
    padding-bottom: 20px;

  }

  li:before {
    position: absolute;
    left: 0;
    top: -5px;
    line-height: 1;
    font-family: $heading-font;
    content: counter(item);
    font-size: 3rem;
    font-weight: bold;
    border-radius: 100%;
    color: inherit;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @include breakpoint(small) {
    padding: 30px;
  }
}