//###########################################################################
// Layout
//###########################################################################

@import 'grid';
@import 'wrapper';
@import 'header';
@import 'footer';

body.debug {
  &:after { // fixed elem that shows which bp the viewport is at
    background-color: rgba(0, 0, 0, .3);
    color: white;
    text-transform: uppercase;
    padding: 17px 25px;
    position: fixed;
    font-size: 15px;
    font-weight: 700;
    bottom: 45px;
    right: 0;
    z-index: 999;

    @each $breakpoint, $config in $debug-breakpoints {
      @if map-get($config, width) {
        @include breakpoint($breakpoint) {
          content: $breakpoint
        }
      }
    }
  }
}
