//###########################################################################
// Flex utilities
//###########################################################################

.flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-sm {
  gap: 12px;
}

.gap-md {
  gap: 20px;
}

.gap-lg {
  gap: 32px;
}

.gap-xl {
  gap: 40px;
}

.grow {
  flex-grow: 1;
}

@include breakpoint-max(small) {
  .justify-content-center--small-down {
    justify-content: center;
  }
}