//###########################################################################
// Icon card
//###########################################################################

.icon-card {
  position: relative;
  height: 100%;
  padding: 32px;
  background: $primary-main;
  border-radius: 6px;

  @include breakpoint(small) {
    padding: 38px 72px;
  }

  h2 {
    padding-right: 90px;

    @include breakpoint(small) {
      padding-right: 100px;
    }
  }

  p {
    line-height: 2;
  }

  &--odd {
    background: $primary-main;

    h2 {
      color: palette(white);
    }

    p, p a, li {
      color: palette(white);
      font-size: 18px;
    }
  }

  &--even {
    background: $primary-secondary;
  }

  // Icon card inner
  //------------------

  .icon-card__inner {
    position: relative;
    z-index: 2;
  }

  // Icon card icon
  //------------------

  .icon-card__icon {
    position: absolute;
    top: -16px;
    right: 16px;
    width: 80px;
    z-index: 1;

    @include breakpoint(small) {
      width: 88px;
      right: 24px;
    }

    @include breakpoint(medium) {
      width: 110px;
      right: 32px;
    }

    img {
      position: relative;
      z-index: 3;
    }

    .icon-card__sparkle {
      position: absolute;
      top: 50px;
      right: 20px;
      width: 80px;
      z-index: 2;

      @include breakpoint(small) {
        top: 50px;
        right: 12px;
        width: 100px;
      }

      @include breakpoint(medium) {
        top: 64px;
        right: 33px;
      }

      @include breakpoint(large) {
        top: 44px;
        right: 20px;
        width: 140px;
      }
    }
  }
}

// Icon card full
//------------------

.icon-card-full {

  .icon-card__header {
    position: relative;
    width: 100%;
    background: $primary-soft;
    padding: 24px 32px 24px 130px;
    border-radius: 6px;

    @include breakpoint(small) {
      padding: 28px 170px;
    }

    .icon-card__icon {
      position: absolute;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
      width: 80px;
      z-index: 1;

      img {
        vertical-align: middle;
      }

      @include breakpoint(small) {
        width: 94px;
      }

      @include breakpoint(medium) {
        width: 110px;
      }
    }

    .icon-card__sparkle {
      position: absolute;
      top: -33px;
      right: -17px;
      width: 90px;

      @include breakpoint-max(440px) {
        display: none;
      }

      @include breakpoint(small) {
        top: -12px;
        right: 30px;
        width: 110px;
      }

      @include breakpoint(medium) {
        top: -12px;
        right: 50px;
      }
    }
  }

  .icon-card__inner {
    background: palette(white);
    padding: 24px 20px;
    margin-top: -6px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: 0 20px 20px 0px rgba(#223343, 0.1);

    @include breakpoint(small) {
      padding: 28px 170px;
    }

    p, p a, li {
      font-weight: 500;

      @include breakpoint(small) {
        font-size: 22px;
      }
    }
  }
}