//###########################################################################
// Pill
//###########################################################################

.pill {
  padding: 12px 5px;
  border-radius: 4px;
  font-size: 1.6rem;
  width: 110px;
  text-align: center;
}