//###########################################################################
// Sliders
//###########################################################################

.slider {

}

// Slider gallery
//------------------

.slider-gallery {
  position: relative;

  .slider__item {
    width: 75%;
    margin-right: 30px;
  }

  .slider__controls {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 32px;

    @include breakpoint(medium) {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-140%);
      margin-bottom: 0;
    }

    .slider__control {
      @include button-unstyled;

      &--next {
        margin-left: 20px;
      }

      .icon {
        > svg path {
          transition: fill 0.2s;
        }
      }

      @include breakpoint(medium) {
        &:hover {
          .icon {
            > svg path {
              fill: $primary-main !important;
            }
          }
        }
      }
    }
  }
}


// Slider stories
//------------------

.slider-stories__container {
  .slider__inner {
    display: flex;
    flex-wrap: wrap;
  }

  .slider__content {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 32px;

    @include breakpoint(medium) {
      width: 40%;
      padding-right: 40px;
    }

    @include breakpoint(large) {
      width: 30%;
      padding-right: 70px;
    }
  }

  .slider__controls {
    display: flex;
    justify-content: flex-start;

    .slider__control {
      @include button-unstyled;

      .icon {
        > svg path {
          transition: fill 0.2s;
        }
      }

      &:hover {
        .icon {
          > svg path {
            fill: $primary-soft !important;
          }
        }
      }

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  @include breakpoint-max(medium) {
    padding: 0 1.33334rem;
  }
}

.slider-stories {
  position: relative;
  z-index: 3;
  width: 100%;
  margin-bottom: 30px;

  .flickity-viewport {
    overflow: hidden;
    transition: height 0.2s;
    padding-top: 20px;
  }

  .slider__item {
    width: 100%;
    margin-top: 26px;
    margin-right: 30px;
    margin-bottom: 30px;

    .card {
      top: 0;
      box-shadow: none;
      transition: top .3s;

      &:hover {
        top: -10px;
      }
    }

    @include breakpoint(small) {
      width: 49%;
    }

    @include breakpoint(medium) {
      width: 400px;
    }

    @include breakpoint(large) {
      width: 366px;
    }
  }

  &:focus {
    outline: none;
  }

  @include breakpoint(medium) {
    width: 60%;
    top: -26px;
  }

  @include breakpoint(large) {
    width: 70%;
  }
}