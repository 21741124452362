//###########################################################################
// CTA
//###########################################################################

// CTA Feature
//------------------

.cta-feature {
  display: flex;
  flex-direction: column;

  @include breakpoint(small) {
    flex-direction: row;
  }

  &--orange {
    .cta-feature__content {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 327.8 82.9'%3E%3Cpath d='M327.8 82.9C210.5 65.3 101.2 37.7 0 0v82.9h327.8z' fill='%23#{str-replace(quote(palette(orange)), '#', '')}'/%3E%3C/svg%3E");
      }
    }
  }

  &--green {
    .cta-feature__content {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 327.8 82.9'%3E%3Cpath d='M327.8 82.9C210.5 65.3 101.2 37.7 0 0v82.9h327.8z' fill='%23#{str-replace(quote(palette(green)), '#', '')}'/%3E%3C/svg%3E");
      }
    }
  }

  &--vertical-center {
    @include breakpoint(medium) {
      align-items: center;

      .cta-feature__content {
        margin-top: 0;
      }
    }
  }

  &--flip {
    @include breakpoint(small) {
      flex-direction: row-reverse;

      .cta-feature__content {
        margin-left: -25px;
      }

      .cta-feature__media {
        margin-right: -25px;
      }
    }
  }
}

.cta-feature__content {
  position: relative;
  background: palette(white);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 26px 26px 34px 26px;

  @include breakpoint(small) {
    padding: 38px 38px 60px 38px;
    box-shadow: 20px 20px 30px 0px rgba(0, 0, 0, 0.1);
    margin-right: -25px;
    margin-top: 50px;
    width: calc(55% + 25px);
    border-radius: 6px;
  }

  @include breakpoint(medium) {
    padding: 50px 50px 80px 50px;
  }

  &:before {
    content: '';
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 195px;
    height: 50px;
    background-size: 100% 100%;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 327.8 82.9'%3E%3Cpath d='M327.8 82.9C210.5 65.3 101.2 37.7 0 0v82.9h327.8z' fill='%23#{str-replace(quote($primary-main), '#', '')}'/%3E%3C/svg%3E");
    border-bottom-left-radius: 6px;

    @include breakpoint(small) {
      display: block;
    }
  }
}

.cta-feature__media {
  @include breakpoint-max(small) {
    img.img--rounded {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  @include breakpoint(small) {
    margin-left: -25px;
    width: calc(45% + 25px);
  }
}

.cta-feature__sparkle {
  position: absolute;
  display: none;

  > svg {
    width: 100%;
  }

  @include breakpoint(medium) {
    display: block;
  }

  &--1 {
    top: -18px;
    right: 20px;
    width: 60px;

    @include breakpoint(large) {
      width: 80px;
    }
  }

  &--2 {
    bottom: 20px;
    right: 20px;
    width: 100px;

    @include breakpoint(large) {
      width: 124px;
    }
  }
}

// CTA Sutherland
//------------------

.cta-sutherland {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;

  @include breakpoint(small) {
    flex-direction: row;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 101%;
    height: 30%;
    background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1169.5 264.5'%3E%3Cpath fill='%23#{str-replace(quote($primary-main), '#', '')}' d='M684.2 264.6c1.1-.1 2.2-.2 3.3-.2h482V.6c-178.8 144-548.7 242.8-976.2 242.8-66.1 0-130.5-2.4-193.4-6.9v27.9h680.9c1.2.1 2.3.1 3.4.2z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 1;
    pointer-events: none;

    @include breakpoint(480px) {
      height: 40%;
    }

    @include breakpoint(small) {
      height: 65%;
    }
  }

  &--orange {
    .cta-sutherland__content {
      background: palette(orange, lighter);
    }

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1169.5 264.5'%3E%3Cpath fill='%23#{str-replace(quote(palette(orange)), '#', '')}' d='M684.2 264.6c1.1-.1 2.2-.2 3.3-.2h482V.6c-178.8 144-548.7 242.8-976.2 242.8-66.1 0-130.5-2.4-193.4-6.9v27.9h680.9c1.2.1 2.3.1 3.4.2z'/%3E%3C/svg%3E");
    }
  }

  &--green {
    .cta-sutherland__content {
      background: palette(green, light);
    }

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1169.5 264.5'%3E%3Cpath fill='%23#{str-replace(quote(palette(green)), '#', '')}' d='M684.2 264.6c1.1-.1 2.2-.2 3.3-.2h482V.6c-178.8 144-548.7 242.8-976.2 242.8-66.1 0-130.5-2.4-193.4-6.9v27.9h680.9c1.2.1 2.3.1 3.4.2z'/%3E%3C/svg%3E");
    }
  }
}

.cta-sutherland__content {
  background: $primary-soft;
  padding: 26px 26px 34px 26px;

  @include breakpoint(small) {
    width: 50%;
    padding: 38px 38px 60px 38px;
  }

  @include breakpoint(medium) {
    padding: 50px 50px 80px 50px;
  }
}

.cta-sutherland__media {
  position: relative;

  picture {
    display: block;
    height: 100%;
  }

  img {
    display: block;
    object-fit: cover;
    height: 100%;
  }

  @include breakpoint-max(small) {
    img {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  @include breakpoint(small) {
    width: 50%;

    img {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

// CTA Singular
//------------------

.cta-singular {
  position: relative;
  padding: 30px 26px 50px 26px;
  border-radius: 6px;
  background: palette(white);
  box-shadow: 0 20px 20px 0px rgba(#223343, 0.1);

  .cta-singular__inner {
    max-width: 720px;
    margin: 0 auto;
  }

  @include breakpoint(small) {
    padding: 50px;
  }

  @include breakpoint(medium) {
    padding: 70px;
    margin-left: 8px;
    margin-right: 8px;
  }
}

.cta-singular__sparkle {
  position: absolute;
  display: none;

  > svg {
    width: 100%;
  }

  @include breakpoint(small) {
    display: block;
  }

  &--1 {
    top: -8px;
    right: -8px;
    width: 60px;

    @include breakpoint(large) {
      width: 80px;
    }
  }

  &--2 {
    top: -8px;
    left: -8px;
    width: 60px;

    @include breakpoint(large) {
      width: 80px;
    }
  }

  &--3 {
    bottom: 0;
    left: -8px;
    width: 100px;

    @include breakpoint(large) {
      width: 124px;
    }
  }

  &--4 {
    bottom: 0;
    right: -8px;
    width: 100px;

    @include breakpoint(large) {
      width: 124px;
    }
  }
}

.cta-singular--w-image-cover {
  padding: 0;

  .cta-singular__wrapper {
    @include breakpoint(small) {
      display: flex;
    }

    .cta-singular__media {
      position: relative;
      width: 100%;

      @include breakpoint-max(small) {
        height: 280px;
      }

      @include breakpoint(small) {
        width: 40%;
        flex-shrink: 0;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        @include breakpoint(small) {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
      }

      .curve {
        display: none;
      }

      @include breakpoint(small) {
        .curve {
          position: absolute;
          top: 0;
          right: -1px;
          height: 100%;
          width: auto;
          display: block;
        }
      }
    }

    .cta-singular__inner {
      padding: 30px 26px 50px 26px;

      @include breakpoint(small) {
        padding: 50px;
      }

      @include breakpoint(medium) {
        padding: 70px;
      }
    }
  }
}

// CTA search
//------------------

.cta-search {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 32px;
  background: palette(white);
  border-radius: 6px;
  box-shadow: 0 10px 20px 0px rgba(#223343, 0.1);

  p {
    margin-bottom: 20px;
  }

  .btn {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @include breakpoint(small) {
    flex-direction: row;

    p {
      margin-bottom: 0;
    }
  }

  @include breakpoint(medium) {
    padding: 52px 72px;
  }
}