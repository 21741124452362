//###########################################################################
// Header
//###########################################################################

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition: transform .3s, background-color .3s;

  .show-desktop, .promo, .top-menu {
    display: none;
  }

  a {
    text-decoration: none;
  }

  .site-header {
    position: relative;
    z-index: 3;
    background-color: $primary-main;
    height: 61px;

    .site-header__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .logo {
    display: inline-block;

    a {
      display: block;
      font-size: 0;
    }

    .aem-logo {
      width: 190px;
      height: 30px;
    }

    .shs-logo {
      width: 224px;
      height: 30px;
    }

  }

  .nav {
    list-style: none;

    > li {
      > a, > span {
        font-weight: bold;
      }
    }
  }

  .nav__icon-group {
    .site-nav__search {
      display: block;
    }

    li {
      padding-bottom: 0;

      &:first-of-type {
        margin-right: 15px;
      }
    }
  }

  .site-nav__search {
    width: 30px;
    height: 30px;
    margin: 14px 0;
    transition: background .3s;
  }

  .search__hold {
    background-color: $primary-lighter;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 11;
    top: 61px;
    height: calc(100vh - 56px);
    transition: opacity .3s, visibility .3s;
    opacity: 0;
    visibility: hidden;
    overflow: auto;
    padding: 80px 0;
  }

  .search__close {
    background-color: $primary-main;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    top: -60px;
    right: 8px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(small) {
      right: 15px;
    }

    @include breakpoint(medium) {
      transform: translateY(0);
    }
  }

  .search__close__wrapper {
    position: relative;
  }
}

.search {
  position: relative;

  input {
    border: none;
    width: calc(100% - 60px);
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;

    &:focus {
      background: #fafafa;
    }
  }

  .submit {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border: none;
    width: 80px;
    background: $primary-main;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }
}

.search__hold, .error__hold {
  .popular {
    font-weight: bold;
    border-bottom: 1px solid #223343;
    padding-bottom: 5px;
  }

  li {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0;
  }

  .btn {
    font-weight: 300;
    font-family: $body-font;
    border-radius: 8px;
    color: #223343;
    background-color: $primary-secondary;
  }

  .header--shs & {
    .btn {
      background-color: $primary-main;
    }
  }
}

#header.header--search {
  .search__hold {
    opacity: 1;
    visibility: visible;
  }
}

#header.header--scrolled {
  background-color: palette(white);
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);

  //.top-menu {
  //  height: 0;
  //  overflow: hidden;
  //}
}

#header.header--hide {
  //transform: translateY(-100%) !important;
  //
  //&.header--open {
  //  transform: translateY(0) !important;
  //}
}

#header.header--no-shadow {
  box-shadow: none;
}

// Only small mobile
//
@include breakpoint-max(400px) {
  #header {
    .logo {
      display: inline-block;

      .aem-logo {
        max-width: 160px;
      }

      .shs-logo {
        max-width: 175px;
      }
    }
  }
}

// Only mobile
//
@include breakpoint-max(medium) {
  #header {
    .show-inline-desktop {
      display: none;
    }

    .logo {
      svg path {
        fill: white !important;
      }
    }

    .site-nav {
      background-color: white;
      position: fixed;
      left: 0;
      width: 100%;
      z-index: 11;
      top: 61px;
      height: 100vh;
      transition: transform .3s;
      transform: translateX(-100%);
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .nav {
      margin: 0;
      padding: 30px 20px 100px 20px;

      > li {
        padding: 0;
        margin-bottom: 15px;

        > a, > span {
          display: block;
          font-size: 2.2rem;
        }
      }
    }

    .nav.nav--aem {
      .sub-nav__hold > a, .sub-nav__hold > span, .sub-nav-dropdown-heading {
        background-image: url('/dist/img/icons/nav-menu-arrow-right-aem.svg');
      }

      .sub-nav__back {
        background-image: url('/dist/img/icons/nav-menu-arrow-left-aem.svg');
      }
    }

    .nav.nav--shs {
      .sub-nav__hold > a,.sub-nav__hold > span, .sub-nav-dropdown-heading {
        background-image: url('/dist/img/icons/nav-menu-arrow-right-shs.svg');
      }

      .sub-nav__back {
        background-image: url('/dist/img/icons/nav-menu-arrow-left-shs.svg');
      }
    }

    .sub-nav__hold {
      > a, > span {
        background-size: 22px 22px;
        background-repeat: no-repeat;
        background-position: center right;
      }
    }

    .list--about .sub-nav-heading {
      background-color: #D0938C;
    }

    .list--as .sub-nav-heading {
      background-color: #EEAA79;
    }

    .list--cs .sub-nav-heading {
      background-color: #CEDBAD;
    }

    .list--training .sub-nav-heading {
      background-color: #F5DBA8;
    }

    .list--school .sub-nav-heading {
      background-color: #A7D5C2;
    }

    .sub-nav {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      transform: translateX(100%);
      transition: transform .3s;

      .sub-nav__inner {
        padding: 30px 20px;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      .sub-nav-heading {
        padding: 10px;
        border-radius: 8px;
        font-size: 2.2rem;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .sub-nav-dropdown-heading {
        font-weight: 500;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 0;
        font-size: 1.8rem;
        background-size: 22px;
        background-repeat: no-repeat;
        background-position: center right;
      }

      .sub-nav-dropdown {
        position: absolute;
        padding: 30px 20px 100px;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        transform: translateX(100%);
        transition: transform .3s;
      }

      li.open {
        .sub-nav-dropdown {
          transform: translateX(0%);
        }
      }

      li {
        padding: 0;

        > a, > span {
          display: block;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 25px;
          font-size: 1.8rem;
          font-weight: 500;
        }
      }
    }

    .sub-nav__back {
      font-size: 1.8rem;
      padding-left: 35px;
      font-weight: 400;
      background-image: url('/dist/img/icons/arrow-left-white.svg');
      background-size: 22px;
      background-repeat: no-repeat;
      background-position: center left;
      margin-bottom: 20px;
      cursor: pointer;
    }

    .open .sub-nav {
      transform: translateX(0);
    }
  }

  #header.header--open {
    .site-nav {
      transform: translateX(0);
    }
  }
}

@include breakpoint(medium) {
  .search {
    input {
      padding: 30px 20px;
      font-size: 2rem;
    }
  }

  #header.header--home {
    .site-header {
      background-color: transparent;
    }

    &:hover {
      background: palette(white);
    }
  }

  #header.header--scrolled {
    .search__hold {
      top: 96px;
      height: 100vh;
    }
  }

  #header {
    .search__hold {
      top: 62px;
      height: calc(100vh - 62px);
    }

    .site-nav__search {
      padding: 0;
    }

    .top-menu, .show-desktop {
      display: block;
    }

    .top-menu {
      height: 62px;
      transition: all 0.3s;
      background-color: $primary-main;

      li {
        margin-right: 35px;
        padding-bottom: 0;

        &:last-of-type {
          margin-right: 0;
        }
      }

      p {
        margin-top: 18px;
        margin-bottom: 21px;
      }

      p a {
        color: white;
        font-size: 1.6rem;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }

      .btn {
        margin-top: 1px;
        margin-bottom: 1px;
        color: $primary-main;
      }
    }

    &.header--shs {
      .top-menu {
        background-color: $primary-secondary;
      }
    }

    .show-inline-desktop {
      display: inline-block;
      padding: 0 !important;
    }

    .nav__icon-group, .hide-desktop {
      display: none;
    }

    .logo {
      display: inline-block;

      .aem-logo {
        width: 209px;
        height: 33px;
      }

      .shs-logo {
        width: 261px;
        height: 35px;
      }
    }

    .site-header {
      background-color: white;
      height: 96px;
    }

    .nav {
      > li {
        padding: 36px 14px;

        > a, > span {
          font-weight: 500;
          line-height: 1;
        }

        &:hover, &.active {
          background-color: palette(grey, lighter);
        }
      }
    }

    .sub-nav__hold {
      position: relative;
      padding: 36px 18px 36px 8px !important;

      > a, > span {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          background-image: url('/dist/img/icons/arrow-down-nav.svg');
          background-size: 11px 6px;
          background-repeat: no-repeat;
          background-position: center left;
          width: 11px;
          height: 6px;
          right: -14px;
          top: 8px;
          transition: transform .3s;
        }
      }

      &:hover {
        background-color: palette(grey, lighter);

        > a, > span {
          &::before {
            transform: rotate(180deg);
          }
        }

        .sub-nav {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .sub-nav {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 100%;
      width: 390px;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s, visibility .3s;

      a {
        font-weight: 500;
        display: block;
        padding: 10px;
        border-radius: 8px;
      }

      .list--about a:hover, .list--about .extra-sub-nav__hold:hover > a {
        background-color: #D0938C;
      }

      .list--as a:hover, .list--as .extra-sub-nav__hold:hover > a {
        background-color: #EEAA79;
      }

      .list--cs a:hover, .list--cs .extra-sub-nav__hold:hover > a {
        background-color: #CEDBAD;
      }

      .list--training a:hover, .list--training .extra-sub-nav__hold:hover > a {
        background-color: #F5DBA8;
      }

      .list--school a:hover, .list--school .extra-sub-nav__hold:hover > a {
        background-color: #A7D5C2;
      }
    }

    .sub-nav__inner {
      background: #FFFFFF;
      box-shadow: 0 35px 30px 0 rgba(0, 0, 0, 0.25);
      border-radius: 0 0 8px 8px;
      position: relative;
    }

    .sub-nav-heading {
      font-weight: bold;
      font-size: 2.2rem;
      padding-left: 12px;
    }

    .sub-nav__hold--last {
      .sub-nav {
        left: initial;
        transform: translateX(0);
        right: 0;
      }
    }

    .sub-nav--double,
    .sub-nav--triple {
      transform: translateX(0%);

      .sub-nav__inner {
        display: flex;
        min-height: 460px;
      }

      .list {
        flex: 1;
        padding: 45px 0 45px 15px;
      }

      .promo {
        display: block;
        flex: 1;
        width: 33.333%;
        background: white;
        position: relative;
        transition: opacity .3s;
        padding: 10px;

        .overlay {
          height: 100%;
          padding: 30px 50px 30px 20px;
          border-radius: 8px;
          background-repeat: no-repeat;
          background-position: bottom;
          background-size: 100%;
        }

        p a {
          padding: 0;
          font-weight: 400;
          text-decoration: underline;

          &:hover {
            opacity: .6;
          }
        }

        &--as {
          .overlay {
            background-color: #EEAA79;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23#{str-replace(quote(palette(orange)), '#', '')}'/%3E%3C/svg%3E");
          }
        }

        &--school {
          .overlay {
            background-color: #A7D5C2;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 370 127.3' style='enable-background:new 0 0 370 127.3' xml:space='preserve'%3E%3Cpath d='M0 86.1v41.2h370V10C260.6 44.8 135.4 71 0 86.1z' style='fill-rule:evenodd;clip-rule:evenodd;fill:%23#{str-replace(quote(palette(green, brand)), '#', '')}'/%3E%3C/svg%3E");
          }
        }
      }
    }

    .sub-nav--double {
      width: 780px;
      left: -375px;


      .promo {
        width: 50%;
      }
    }

    .sub-nav--triple {
      width: 916px;
      left: -345px;


      .promo {
        width: 33.333%;
      }

      .sub-nav-heading--no-desktop {
        display: none;
      }

      .sub-nav-dropdown {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        background: white;
        left: 0;
        width: 33.333%;
        margin-left: 33.333%;
        padding-top: 97px;
        padding-left: 10px;
        padding-right: 5px;
        z-index: 3;
      }

      .sub-nav-category {
        background: white;
        border-radius: 8px;
      }

      .extra-sub-nav__hold {

        > a, > span {
          background-image: url('/dist/img/icons/arrow-right-nav.svg');
          background-size: 7px 12px;
          background-repeat: no-repeat;
          background-position: center right 15px;
        }

        &:hover {
          .sub-nav-dropdown {
            display: block;
          }
        }
      }
    }

    .sub-nav__back {
      display: none;
    }

    .list {
      text-align: left;
      padding: 45px 15px 20px;

      li {
        display: block;
        padding-bottom: 5px;

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    nav {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        display: inline-block;
        vertical-align: middle;
        padding-bottom: 0;
      }
    }
  }
}

@include breakpoint(large) {
  #header {
    .nav {
      > li {
        padding: 34px 20px;
      }
    }

    .sub-nav__hold {
      padding: 34px 28px 34px 14px !important;

      > a {
        &::before {
          right: -18px;
        }
      }
    }

    .sub-nav--triple {
      width: 1233px;
      left: -521px;
    }
  }
}