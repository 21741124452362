//###########################################################################
// Contact block
//###########################################################################

.contact-block {
  border-radius: 8px;
  background: palette(white);
  box-shadow: 0 -4px 20px 0 rgba(34,51,67, 0.20);
  overflow: hidden;
}

// Contact block information
//------------------

.contact-block__information {
  position: relative;
  padding: 32px;
  height: 100%;
  background: $primary-soft;
  overflow: hidden;

  @include breakpoint(medium) {
    padding: 54px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 220px;
    pointer-events: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 420 345' preserveAspectRatio='none'%3E%3Cpath fill='%23#{str-replace(quote($primary-secondary), '#', '')}' d='M420 0c-43.6 55-110.6 96.1-201.1 123.5-47.5 14.3-121.2 25.1-218.9 32V345h420V0z'/%3E%3C/svg%3E");

    .contact-block--shs & {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 420 345' preserveAspectRatio='none'%3E%3Cpath fill='%23#{str-replace(quote($primary-main), '#', '')}' d='M420 0c-43.6 55-110.6 96.1-201.1 123.5-47.5 14.3-121.2 25.1-218.9 32V345h420V0z'/%3E%3C/svg%3E");
    }

    @include breakpoint(medium) {
      height: 300px;
    }

    @include breakpoint(large) {
      height: 350px;
    }
  }
}

.contact-block__information__inner {
  position: relative;
  z-index: 1;
  padding-bottom: 200px;

  @include breakpoint(medium) {
    padding-bottom: 300px;
  }

  @include breakpoint(large) {
    padding-bottom: 350px;
  }
}

// Contact form
//------------------

.contact-block__form {
  padding: 32px;

  @include breakpoint(medium) {
    padding: 54px;
  }
}

// Contact service selector
//------------------

.contact-block__service-selector__item {
  display: block;
  border-radius: 8px;
  padding: 12px 30px;
  text-decoration: none;
  text-align: center;
  line-height: 1.2;
  font-weight: 300;
  color: palette(black, font);
  background: $primary-soft;

  &--selected {
    background: $primary-main;
    color: palette(white);
  }
}


// Contact block information - scroll trigger
//------------------

#contact-block-scroll-trigger {
  position: relative;
  top: -200px;
}